function FlightResultProcess(handler) {
    this.progressUrl = dataActive.location.marketplace + "/v1/search/progress";
    this.resultUrl = dataActive.location.marketplace + "/v1/search/results";
    this.currentCurrency;
    var intervalId;
    var progressFinished;
    var isProgressFinished = false;
    var delay = 3000;
    var me = this;
    var waitTime = 90000;
    var $resultItem = handler.domNode.find(".flight-items");
    var $moreResult = handler.domNode.find(".more-result");
    var $waiting = handler.domNode.find(".search-loader");
    var $filters = handler.domNode.find(".flight-filters");
    var $filtersForm;
    var $pageNumber;
    var messageCode = "t<wt";
    var changeSearchDate = handler.domNode.find('.change-search-date');
    var clientHalt;
    var searchBtn = handler.domNode.prev().find('.service-search-btn');
    var dataLenght = 0;
    var headers = {};
    var loginInformation = getStorage("loginInformation");
    if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
        headers = { 'Authorization': 'Bearer ' + loginInformation.token };
    }
    searchBtn.prop('disabled', true);
    dataActive.hooks.register('afterAjaxError', this.afterAjaxError);

    this.checkProgress = function () {
        ajax({
            url: me.progressUrl,
            method: "get",
            data: {
                sessionId: dataActive.location.query.sessionId
            },
            success: function (data) {
                if (data.percent == 100) {
                    isProgressFinished = true;
                    me.searchResult(messageCode + ",p=100");
                } else {
                    me.searchResult(messageCode + ",p<100");
                }
            },
        });
    }

    this.progressFinished = function () {
        messageCode = "t>wt";
        if (isProgressFinished == false) {
            isProgressFinished = true;
            me.kill();
            ajax({
                url: me.progressUrl,
                method: "get",
                data: {
                    sessionId: dataActive.location.query.sessionId
                },
                success: function (data) {
                    if (data.percent == 0) {
                        me.manageResults(messageCode + ",p=0", data)
                    } else if (data.percent < 100) {
                        me.searchResult(messageCode + ",p<100");
                    } else {
                        me.searchResult(messageCode + ",p=100");
                    }

                },
            });
        } else {
            me.kill();
        }
    }

    this.searchResult = function (latestMessageCode) {
        ajax({
            url: me.resultUrl,
            method: "get",
            data: {
                sessionId: dataActive.location.query.sessionId
            },
            headers: headers || {},
            success: function (data) {
                searchBtn.prop('disabled', false);
                me.currentCurrency = data.queryParams['marketplace_currency'].abb;
                if (data.queryParams.legs[0].destination.city.abb == "IST" || data.queryParams.legs[0].destination.city.abb == "DXB" | data.queryParams.legs[0].destination.city.abb == "LON") {
                    me.showWeather(data.queryParams.legs[0].destination.city);
                }
                var resultCount = data.pages.resultCount;
                if (isProgressFinished == true) {
                    if (resultCount == 0) {
                        me.manageResults(latestMessageCode + ",d=0", data);
                        me.kill();
                        return;
                    } else {
                        me.manageResults(latestMessageCode + ",d!=0", data);
                    }
                } else {
                    if (dataLenght != resultCount) {
                        dataLenght = resultCount;
                        me.manageResults(latestMessageCode + ",d!=0", data);
                    }
                }
            },
        })
    }

    this.moreResult = function () {
        $moreResult.hide();
        $waiting.show();
        $pageNumber.val(parseInt($moreResult.data('currentPage')) + 1);
        ajax({
            url: me.resultUrl,
            method: "get",
            data: $filtersForm.serialize(),
            success: function (data) {
                me.mapContent(data, data.pages);
                $moreResult.data('currentPage', data.pages.page);
                if ($moreResult.data('pageCount') > parseInt(data.pages.page)) {
                    $moreResult.show();
                }
            },
        })
    }

    this.mapFilters = function (filters) {
        $filters.empty();
        $filters.each((index, item) => {
            if ($(window).width() >= 992 && $(item).hasClass("desktop-filters")) {
                $(item).append(view("flight/flight-filters"));
            } else if ($(window).width() < 992 && $(item).hasClass("mobile-filters")) {
                $(item).append(view("flight/flight-filters"));
            }
        });
        $filtersForm = $filters.find('.flight-filter-form');
        $pageNumber = $filtersForm.find(".page-number");
        var $newPriceSection = $filters.find(".price-section");
        $newPriceSection.html(view("partials/price-filter", { filters: filters }));
        priceSlider(me.currentCurrency);


        var $airLines = $filters.find(".airline-section");
        var airlines = filters.airlines;
        var airlineLowPrice = filters.airlineLowPrice;
        $airLines.html(view("flight/airline-filter", { airlines: airlines }));
        var $departStops = $filters.find(".depart-stops");
        $departStops.html(view('flight/filter-depart-stops', { filters: filters }));

        if (!$.isEmptyObject(filters.returnStop)) {
            var $returnStops = $filters.find(".return-stops");
            $returnStops.html(view('flight/filter-return-stops', { filters: filters }));
        }

        var $departTime = $filters.find(".depart-time");
        $departTime.html(view('flight/depart-time-fliter', { filters: filters }));

        if (!$.isEmptyObject(getStorage("flightSearchDetails")["return"])) {
            var $returnTime = $filters.find(".return-time");
            $returnTime.html(view('flight/return-time-fliter', { filters: filters }));
        }
        checkboxes();
        handler.domNode.find('.low-price-results .result-table').html(view('flight/low-price-results', { airlines: airlines, airlineLowPrice: airlineLowPrice, currencyAbbreviation: me.currentCurrency }));
        handler.domNode.find('.airline-name').each(function (i) {
            var $this = $(this);
            var len = $this.text().trim().length;
            if (len > 12) {
                $this.text($this.text().substr(0, 12) + '...');
            }
        })
        handler.domNode.find('.main-table').clone(true).appendTo('#table-scroll').addClass('clone');
        me.filterByAirline();
    }

    this.filterByAirline = function () {
        handler.domNode.find('.filter-flight-with-airline').click(function () {
            handler.domNode.find('.airline-filter-sidebar').each((index, item) => {
                let $item = $(item);
                if ($item.data('airline') == $(this).data('airline')) {
                    if ($item.is(':checked')) {
                        $item.attr('checked', false);
                        $item.parent().removeClass('checked');
                    } else {
                        $item.attr('checked', true);
                        $item.parent().addClass('checked');
                    }
                } else {
                    $(item).attr('checked', false);
                    $item.parent().removeClass('checked');
                }
            })
            $filters.find(".apply-filter")[0].click();
        })
    }

    this.configFilters = function (filters, pages) {
        $filters.show();
        $filters.find(".apply-filter").click(function () {
            clearTimeout(clientHalt);
            me.applyFilters();
            $filters.next().click();
        });
        $filters.find(".reset-filter").click(function () {
            clearTimeout(clientHalt);
            $filtersForm[0].reset();
            $filters.empty();
            me.mapFilters(filters);
            me.configFilters(filters, pages);
            me.applyFilters();
            $filters.next().click();

        });
        $filters.find(".session-id").val(dataActive.location.query.sessionId);
        $pageNumber.val(1);
        this.loadMore(pages);
    }

    this.loadMore = function (pages) {
        if ($moreResult.data('pageCount') == undefined) {
            $moreResult.click(me.moreResult)
        }
        $moreResult.data('pageCount', pages.pageCount);
        $moreResult.data('currentPage', pages.page);
        if (pages.pageCount > parseInt(pages.page)) {
            $moreResult.show();
        } else {
            $moreResult.hide();
        }
    }

    this.applyFilters = function () {
        $resultItem.empty();
        $moreResult.hide();
        $waiting.show();
        $pageNumber.val(1);
        ajax({
            url: me.resultUrl,
            method: "get",
            data: $filtersForm.serialize(),
            success: function (data) {
                me.mapContent(data, data.pages);
                $pageNumber.val(2);
                me.loadMore(data.pages);
            },
        })
    }

    function uniqId() {
        return Math.round(new Date().getTime() + (Math.random() * 100));
    }

    this.mapContent = function (data, pages) {
        var destination;
        var origin = data.queryParams.legs[0].origin.city.title;
        var destination = data.queryParams.legs[0].destination.city.title;
        $waiting.hide();
        data.data.forEach((item, index) => {
            indexItem = uniqId();
            $view = $(view("flight/flight-item", { item: item, indexItem: indexItem }));
            if(data.queryParams.tripType == 'openReturn') {
                $view.find('.flight-legs').append(view('flight/leg', { leg: item.serviceInfo.legs[0], legIndex: 0, item }));
                $view.find('.leg-details').append(view('flight/leg-details', { leg: item.serviceInfo.legs[0] }));
            }
            else {
                item.serviceInfo.legs.forEach((leg, legIndex) => {
                    $view.find('.flight-legs').append(view('flight/leg', { leg, legIndex, item }));
                    $view.find('.leg-details').append(view('flight/leg-details', { leg }));
                });
            }
            $resultItem.append($view);
            var baggageBtn = $view.find('.baggage-btn');
            var baggageRow = handler.domNode.find('.baggage-details');
            baggageBtn.on('click', function (e) {
                e.preventDefault();
                baggageRow.empty();
                item.serviceInfo.legs.forEach(leg => {
                    leg.segments.forEach((segment,segmentIndex) => {
                        baggageRow.append('<tr><td data-label="' + trans('Departure') + '">' + segment.departure.airport.city.abb + '</td><td data-label="' + trans('Arrival') + '">' + segment.arrival.airport.city.abb + '</td><td data-label="' + trans('Flight_No') + '">' + segment.flight_number + '</td><td data-label="' + trans('Baggage') + '" class="flight-baggage">' + (segment.luggage || "-") + '</td></tr>')
                    })
                });
                handler.domNode.find('.baggage-modal').magnificPopup('open');
            })

            var breakDownBtn = $view.find('.break-down-btn');
            var breakDownDetails = handler.domNode.find('.break-down-details');
            breakDownBtn.on('click', function (e) {
                e.preventDefault();
                handler.domNode.find('.break-down-modal').magnificPopup('open');
                breakDownDetails.empty();
                var breakDowns = item.priceInfo.breakDowns;
                var counter = 0;
                for (var i in breakDowns) {
                    breakDownDetails.append(`<tr><td data-label="${trans('Passenger_Type')}">${trans(i.charAt(0).toUpperCase() + i.slice(1))} <br> <i class="fa fa-times"></i> ${breakDowns[i].passengersCount} </td><td data-label="${trans('Base_Fare')}">${me.currentCurrency} ${currencyFormatter(breakDowns[i].baseFare)}<br><i class="fa fa-times"></i>${breakDowns[i].passengersCount} </td><td data-label="${trans('Tax_Fee')}"> ${me.currentCurrency} ${currencyFormatter(breakDowns[i].tax)} <br> <i class="fa fa-times"></i>${breakDowns[i].passengersCount} </td><td data-label="${trans('Service Tax')}" >${me.currentCurrency} ${currencyFormatter(breakDowns[i].serviceCharge)} <br> <i class="fa fa-times"></i>${breakDowns[i].passengersCount}</td><td data-label="${trans('Per_Passenger')}">${me.currentCurrency} ${currencyFormatter(breakDowns[i].totalFare)}<br> <i class="fa fa-times"></i>${breakDowns[i].passengersCount} </td><td data-label="${trans('commission')}">${me.currentCurrency} ${currencyFormatter(breakDowns[i].commission)} <br> <i class="fa fa-times"></i>${breakDowns[i].passengersCount} </td><td data-label="${trans('Total')}">${me.currentCurrency} ${currencyFormatter(breakDowns[i].passengerTypeTotal.payable)}</td></tr>`);
                    counter += 1;
                }
                breakDownDetails.parent().next().find('.grand-total').html(me.currentCurrency + currencyFormatter(item.priceInfo.payable));
            });
            var ruleBTN = $view.find('.rule-btn');
            var fareRulesSection = handler.domNode.find('.fare-rules');
            var ruleModal = handler.domNode.find('.rule-modal');
            ruleBTN.on('click', function (e) {
                e.preventDefault();
                ruleBTN.html('<i class="fa fa-circle-o-notch fa-spin buttonload"></i>');
                ruleBTN.attr('disabled', true);
                fareRulesSection.empty();
                if (item.serviceInfo.fareRules == undefined) {
                    var flightReferenceId = item.flightBufferReferenceId;
                    ajax({
                        url: dataActive.location.marketplace + "/v1/profile/flight",
                        method: "get",
                        data: {
                            referenceId: flightReferenceId
                        },
                        success: function (data) {
                            ruleBTN.attr('disabled', false);
                            ruleBTN.html(trans('RULE'));
                            if ($.isEmptyObject(data.data.serviceInfo.fareRules)) {
                                fareRulesSection.append('</p>There is no rule supplied by the supplier now. It my announce in further steps of the booking process.</p>');
                            } else {
                                data.data.serviceInfo.fareRules.forEach((ruleitem, ruleIndex) => {
                                    var title = "";
                                    for (var x in ruleitem.group) {
                                        title += x + ":" + ruleitem.group[x] + " | ";
                                    }
                                    fareRulesSection.append(view('flight/fare-rule', { ruleitem: ruleitem, title: title }));
                                });
                                item.serviceInfo.fareRules = data.data.serviceInfo.fareRules;
                            }
                            ruleModal.magnificPopup('open');
                        },
                        error: function (data) {
                            ruleBTN.attr('disabled', false);
                            ruleBTN.html(trans('RULE'));
                            swal({
                                text: trans("Flight Fare Rules Not Found!"),
                                icon: "error",
                                dangerMode: true,
                            })
                        }
                    });
                } else {
                    ruleBTN.attr('disabled', false);
                    if ($.isEmptyObject(item.serviceInfo.fareRules)) {
                        fareRulesSection.append('</p>There is no rule supplied by the supplier now. It my announce in further steps of the booking process.</p>');
                    } else {
                        item.serviceInfo.fareRules.forEach((ruleitem, ruleIndex) => {
                            var title = "";
                            for (var x in ruleitem.group) {
                                title += x + ":" + ruleitem.group[x] + " | ";
                            }
                            fareRulesSection.append(view('flight/fare-rule', { ruleitem: ruleitem, title: title }));
                        });
                    }
                    ruleBTN.html(trans('RULE'));
                    ruleModal.magnificPopup('open');
                }
            })

            var bookBtn = $view.find('.flight-book-now');
            bookBtn.click(me.booking);
        });

        setStorage("currencyAbbreviation", me.currentCurrency);
        me.searchAgainAfterFifteenMinute();
        handler.domNode.find(".search-count").removeClass('display-none').text(trans('ABOUT @1 FLIGHTS WERE FOUND FROM @2 TO @3!' + pages.resultCount + ',' + origin.toUpperCase() + ',' + destination.toUpperCase() + ''));
        handler.domNode.find('.rule-modal').magnificPopup({
            type: 'inline',
            fixedContentPos: true
        });
        handler.domNode.find('.baggage-modal').magnificPopup({
            type: 'inline',
            fixedContentPos: true
        });
        handler.domNode.find('.break-down-modal').magnificPopup({
            type: 'inline',
            fixedContentPos: true
        });
    }


    this.mapPrimaryResults = function (data) {
        $waiting.hide();
        var destination;
        var origin = data.queryParams.legs[0].origin.city.title;
        var destination = data.queryParams.legs[0].destination.city.title;
        data.data.forEach((item, index) => {
            indexItem = uniqId();
            $view = $(view("flight/flight-item", { item: item, indexItem: indexItem }));
            item.serviceInfo.legs.forEach((leg, legIndex) => {
                $view.find('.flight-legs').append(view('flight/leg', { leg, legIndex, item }));
                $view.find('.leg-details').append(view('flight/leg-details', { leg }));
            })
            $resultItem.append($view);
            $view.find('.flight-book-now').attr('disabled', true);
            $view.find('.rule-btn').attr('disabled', true);
            $view.find('.break-down-btn').attr('disabled', true);
            $view.find('.baggage-btn').attr('disabled', true);
        });
        handler.domNode.find(".search-count").removeClass('display-none').html('<i class="fa fa-circle-o-notch fa-spin buttonload _mr-5"></i>' + ' ' + trans('ABOUT @1 FLIGHTS WERE FOUND FROM @2 TO @3!' + data.pages.resultCount + ',' + origin.toUpperCase() + ',' + destination.toUpperCase() + ''));
    }

    this.changeSearchDate = function (data) {
        changeSearchDate.removeClass('display-none').html('');
        var currentCalendar = getConfig('currentCalendar', 'gregorian');
        data.queryParams.legs.forEach((leg,legIndex) => {
            const changeDateFile = $(view("flight/change-date" , {data:data , legIndex: legIndex}));
            changeSearchDate.append(changeDateFile);
            const nextDayBtn = $(changeDateFile).find(".depart-next-day");
            const prevDayBtn = $(changeDateFile).find(".depart-previous-day");
            const departDate = leg.departure;
            const today      = dayjs(new Date()).format('YYYY-MM-DD');
            if (today == departDate) {
                $(prevDayBtn).attr("disabled", true);
            }
            nextDayBtn.on('click', function() {
                let tomarrow = new Date((new Date(departDate)).getTime() + (24 * 60 * 60 * 1000)).toISOString().split("T")[0];
                if (currentCalendar == 'gregorian') {
                    $(handler.domNode.prev().find('.departure-flight-date')[legIndex]).val(tomarrow);
                } else {
                    $(handler.domNode.prev().find('.departure-flight-date')[legIndex]).val((new persianDate(new Date(tomarrow))).toLocale('en').format("YYYY-MM-DD"));
                }
                $(handler.domNode.prev().find('.departure')[legIndex]).val(tomarrow);
                searchBtn.click();
            });
            prevDayBtn.on('click', function() {
                let yesterday = new Date((new Date(departDate)).getTime() - (24 * 60 * 60 * 1000)).toISOString().split("T")[0];
            if (currentCalendar == 'gregorian') {
                $(handler.domNode.prev().find('.departure-flight-date')[legIndex]).val(yesterday);
            } else {
                $(handler.domNode.prev().find('.departure-flight-date')[legIndex]).val((new persianDate(new Date(yesterday))).toLocale('en').format("YYYY-MM-DD"));
            }
            $(handler.domNode.prev().find('.departure')[legIndex]).val(yesterday);
            searchBtn.click();
            })

        });
    }

    this.manageResults = function (latestMessageCode, data) {
        switch (latestMessageCode) {
            case "t<wt,p=100,d=0":
                me.changeSearchDate(data);
                me.showNotFoundError();
                me.kill();
                break;
            case "t<wt,p=100,d!=0":
                $resultItem.empty();
                me.mapData(data);
                me.kill();
                break;
            case "t<wt,p<100,d!=0":
                $resultItem.empty();
                me.mapPrimaryResults(data);
                break;
            case "t>wt,p=0":
            case "t>wt,p<100,d=0":
                me.showSupplierError();
                me.kill();
                break;
            case "t>wt,p<100,d!=0":
                $resultItem.empty();
                me.mapData(data);
                me.kill();
                break;
            case "t>wt,p=100,d=0":
                me.showNotFoundError();
                me.kill();
                break;
            case "t>wt,p=100,d!=0":
                $resultItem.empty();
                me.mapData(data);
                me.kill();
                break;
            default:
                me.showNotFoundError();
                me.kill();
                break
        }


    }

    this.showNotFoundError = function () {
        $waiting.hide();
        handler.domNode.find('.show-not-found-message').show();
    }

    this.showSupplierError = function () {
        $waiting.hide();
        handler.domNode.find('.show-supplier-error').show();
        handler.domNode.find('.search-again-btn').click(function () {
            searchBtn.click();
        });

    }

    this.mapData = function (data) {
        me.changeSearchDate(data);
        me.mapContent(data, data.pages);
        me.mapFilters(data.filters);
        me.configFilters(data.filters, data.pages);
    }

    this.booking = function (event) {
        event.preventDefault();
        dataActive.hooks.clear('afterAjaxError');
        let $bookNowBtn = $(this);
        $bookNowBtn.attr("disabled", true);
        $bookNowBtn.html('<i class="fa fa-circle-o-notch fa-spin buttonload"></i>');
        clearTimeout(clientHalt);
        var loginInformation = getStorage("loginInformation");
        var referenceId = $bookNowBtn.data("value");
        if (!$.isEmptyObject(loginInformation) && loginInformation.token !== "") {
            var headers = { 'Authorization': 'Bearer ' + loginInformation.token };
        }
        ajax({
            url: dataActive.location.marketplace + "/v1/book/flight/create",
            method: "post",
            headers: headers || {},
            data: {
                referenceId: referenceId,
            },
            success: function (data) {
                dataActive.pageRoute("passengerInfo", { referenceId: data.bookingReferenceId, type: "flight" });
            },
            error: function (data, validation) {
                $bookNowBtn.attr("disabled", false);
                $bookNowBtn.html('').text(trans('Book_Now'));
                dataActive.defaultAjaxError(data, validation);
            }
        });
    }

    this.afterAjaxError = function () {
        dataActive.pageRoute('index');
    };

    this.kill = function () {
        clearInterval(intervalId);
        clearTimeout(progressFinished);
        $('.progress-bar').hide();
    }

    function checkboxes() {
        $('.icheck, .iradio').iCheck({
            checkboxClass: 'icheck',
            radioClass: 'iradio'
        });
    }

    this.showWeather = function (city) {
        let weatherSection = handler.domNode.find('.weather-section');
        if (weatherSection.children().length == 0) {
            var cityId;
            if (city.abb == 'LON') {
                cityId = 2643743;
            } else if (city.abb == 'DXB') {
                cityId = 3435280;
            } else if (city.abb == 'IST') {
                cityId = 745044;
            }
            ajax({
                url: 'https://api.openweathermap.org/data/2.5/weather?id=' + cityId + '&units=metric&appid=6ab7aa801aa97e116e07d47b172d88ff',
                method: 'get',
                data: {},
                xhr: function () {
                    var xhr = $.ajaxSettings.xhr();
                    var setRequestHeader = xhr.setRequestHeader;
                    xhr.setRequestHeader = function (name, value) {
                        if (name == 'pid') return;
                        setRequestHeader.call(this, name, value);
                    }
                    return xhr;
                },
                success: function (data) {
                    weatherSection.html(view('partials/weather-template', { data: data, city: city }));
                },
            });
        }
    }


    this.searchAgainAfterFifteenMinute = function () {
        clientHalt = setTimeout(() => {
            if (dataActive.location.routeName == 'flightResult') {
                swal({
                    text: trans('To get updated result renew your search'),
                    icon: "info",
                    buttons: {
                        cancel: {
                            text: trans('To Homepage'),
                            value: null,
                            visible: true,
                            className: "",
                            closeModal: true,
                        },
                        confirm: {
                            text: trans("Ok"),
                            value: true,
                            visible: true,
                            className: "",
                            closeModal: true
                        }
                    },
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                }).then((response) => {
                    if (response == true) {
                        searchBtn.click();
                    } else {
                        dataActive.pageRoute('index');
                    }
                })
            }
        }
            , 900000);
    }

    ///>initial codes
    if ($(window).width() > 992) {
        dataActive.hooks.register('afterPageRoute', function () {
            handler.domNode.find('.sticky-col').stick_in_parent({
                parent: '#sticky-parent',
            })
        });
    }
    if ($(window).width() < 992) {
        mobileFilters();
        magnificLightbox();
    }
    this.checkProgress();
    $('.progress-bar-fill').delay(500).queue(function () {
        $(this).css('width', '100%');
    });
    if (!isProgressFinished) {
        intervalId = setInterval(this.checkProgress, delay);
        progressFinished = setTimeout(this.progressFinished, waitTime);
    }

    dataActive.hooks.register('changeCurrency', function (currency) {
        if (!currencyChangeAlert()) {
            return;
        }
        if (!$.isEmptyObject(currency)) {
            dataActive.updateConfig('currentCurrency', currency);
            searchBtn.click();
        }
    });
    dataActive.hooks.register('changeLanguage', function (language) {
        if (languageChangeAlert()) {
            return language;
        }
    });
    dataActive.hooks.register('beforePageRoute', function (configs) {
        me.kill();
        clearTimeout(clientHalt);
        return configs;
    });
}